import axios from "axios";

export default function authHeader() {
  const user = localStorage.getItem("user");

  if (user) {
    // console.log(localStorage.getItem("user"));
    // return { Authorization: 'Bearer ' + user.accessToken }; // for Spring Boot back-end
    return (axios.defaults.headers.common["x-access-token"] = user);

    // { "x-access-token": user.accessToken }; // for Node.js Express back-end
  } else {
    // console.log("אין התאמה");
    return {};
  }
}
