import "./content.scss";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Carousell from "../carousel/carousel";
import Products from "../products/products";
import QuiltedImageList from "../imgList/imgList";
import Contact from "../contact/contact";
import Details from "../details/details";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import React from "react";

const Content = () => {
  return (
    // <div>

    // <Paper sx={{ borderBottomRightRadius: "30px" }} elevation={3} elevation={3}>
    <Box
      id="containerNav"
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        className="containerIconsPhoneWhatsapp"
        // style={{
        //   position: "fixed",
        //   right: "5px",
        //   zIndex: "99999",
        //   top: "50%",
        // }}
      >
        <WhatsAppIcon
          className="stickyIcons"
          id="whatsApp"
          onClick={() =>
            window.open(
              "https://api.whatsapp.com/send/?phone=972509731118&",
              "_BLANK"
            )
          }
        />
        <br />

        <CallIcon
          id="call"
          className="stickyIcons"
          onClick={() => (window.location = "tel:+972509731118")}
        />
      </div>
      {/* <Carousell /> */}
      <Details />
      <Products />

      <QuiltedImageList />
      <Contact />
    </Box>
    // </Paper>

    // </div>
  );
};

export default Content;
