import "./dashboard.scss";
import Nav from "../nav/nav";
import Footer from "../footer/footer";
import Content from "../content/content";
import Customers from "../Customers/Customers";
import CustomersTest from "../Customers/CustomersTest";

import { Box } from "@mui/material";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import React, { useState, useEffect, useContext } from "react";
import http from "../../services/http";
// import Negishot from "../negishot/negisot";

import { adminPanelContext } from "../../services/conext/adminPanel";

import { endpoint1, endpoint2 } from "../../services/config";
import axios from "axios";
import authHeader from "../../services/Auth/auth-header";
import DataPolicy from "../dataPolicy/dataPolicy";

const Dashboard = (props) => {
  const { getUserLogin } = useContext(adminPanelContext);

  useEffect(() => {
    async function getUser() {
      authHeader();

      return await http.get(`${endpoint1}/user`).then((response) => {
        getUserLogin(response.data);
      });
    }

    getUser();
  }, []);

  return (
    <Box>
      <Router>
        <Route path="/" component={Nav} />

        <Switch>
          <Route exact path="/admin">
            <Customers />
          </Route>

          <Route exact path="/dataPolicy">
            <DataPolicy />
          </Route>

          <Route path="/">
            <Content />
            <Footer />
          </Route>
        </Switch>
      </Router>
    </Box>
  );
};

export default Dashboard;
