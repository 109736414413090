import { CacheProvider } from "@emotion/react";
import React, { useRef, useEffect, useState, useContext } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import createCache from "@emotion/cache";
import EventWrapper from "../EventWrapper/EventWrapper";
import { io } from "socket.io-client";
import rtlPlugin from "stylis-plugin-rtl";
import moment from "moment";
import "moment/locale/he";

// Context
import { adminPanelContext } from "../../../services/conext/adminPanel";

// Scss
import "./CalendarComp.scss";
import EditAppointment from "../Edit/Edit";
import {
  endpoint1,
  endpoint2,
  endpointSocketIo,
} from "../../../services/config";

import AbortController from "abort-controller";

const CalendarComp = () => {
  const abortController = useRef();

  const {
    getAllData,
    editappointment,
    setAllClients,
    setAllEvents,
    allEvents,
    setEventSelect,
    eventSelect,
    allClients,
  } = useContext(adminPanelContext);

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });

  const localizer = momentLocalizer(moment);

  const socket = useRef();

  useEffect(() => {
    let today = (window.document.getElementsByClassName(
      "rbc-btn-group"
    )[0].children[0].innerHTML = "היום");

    let back = (window.document.getElementsByClassName(
      "rbc-btn-group"
    )[0].children[1].innerHTML = "אחורה");

    let next = (window.document.getElementsByClassName(
      "rbc-btn-group"
    )[0].children[2].innerHTML = "קדימה");

    let month = (window.document.getElementsByClassName(
      "rbc-btn-group"
    )[1].children[0].innerHTML = "חודש");

    let week = (window.document.getElementsByClassName(
      "rbc-btn-group"
    )[1].children[1].innerHTML = "שבוע");

    let day = (window.document.getElementsByClassName(
      "rbc-btn-group"
    )[1].children[2].innerHTML = "יום");

    let agenda = (window.document.getElementsByClassName(
      "rbc-btn-group"
    )[1].children[3].innerHTML = "תורים מרוכזים");
  }, []);

  useEffect(() => {
    try {
      socket.current = io(endpointSocketIo);
      socket.current.on("eventCreated", async (data) => {
        // if (data.length) {

        setAllClients([]);

        // signal: abortController.signal,
        let req = await data?.map((client) => {
          setAllClients((prev) => [...prev, client]);
        });

        return {
          req,
          signal: abortController.current.signal,
        };
      });

      socket.current.on("eventUpdated", async (data) => {
        // console.log(data);

        // if (data.length) {

        // משיכה של כל הקליינטים
        setAllClients([]);

        // משיכה של כל התורים
        setAllEvents([]);

        // עריכת תור
        setEventSelect({
          name: "",
          id: "",
          title: "",
          start: "",
          end: "",
        });
        // }

        let req = await data?.map((client) => {
          setAllClients((prev) => [...prev, client]);
          return client?.services?.map((y) => {
            return setAllEvents((prev) => [
              ...prev,
              {
                id: client._id,
                idService: y._id,
                name: client.name,
                title: `${client.name}-${y.title}`,
                color: y.color,
                onlyTitle: y.title,
                phone: client.phone,
                start: new Date(y.start),
                end: new Date(y.end),
              },
            ]);
          });
        });

        return {
          req,
          signal: abortController.current.signal,
        };
      });

      socket.current.on("allData", async (data) => {
        let req = await data?.map((client) => {
          setAllClients((prev) => [...prev, client]);
          return client?.services?.map((y) => {
            return setAllEvents((prev) => [
              ...prev,
              {
                id: client._id,
                idService: y._id,
                name: client.name,
                title: `${client.name}-${y.title}`,
                color: y.color,
                onlyTitle: y.title,
                phone: client.phone,
                // start: new Date(y.start),
                // end: new Date(y.end),

                start: moment(y.start).toDate(),
                end: moment(y.end).toDate(),
              },
            ]);
          });
        });

        return {
          req,
          signal: abortController.current.signal,
        };
      });
    } catch (e) {
      console.log(e);
    }

    return () => {
      setAllClients([]);

      // משיכה של כל התורים
      setAllEvents([]);

      // עריכת תור
      setEventSelect({
        name: "",
        id: "",
        title: "",
        start: "",
        end: "",
      });

      socket.current.disconnect();
    };
    // }, [allClients, allEvents, eventSelect]);
  }, []);

  useEffect(() => {
    abortController.current = new AbortController();

    return () => {
      abortController.current.abort();
    };
  }, []);

  function handleEventSelection(e) {
    console.log(e);

    return setEventSelect({ ...e });
  }

  return (
    <div className="containerCalendar">
      {eventSelect?.title?.length ? (
        <span className="aboutClock">
          <EditAppointment
            eventSelect={eventSelect}
            editappointment={editappointment}
            setAllEvents={setAllEvents}
            setAllClients={setAllClients}
            openWindow={true}
            setEventSelect={setEventSelect}
            getAllData={getAllData}
            socket={socket}
          />
        </span>
      ) : null}

      <CacheProvider value={cacheRtl}>
        <Calendar
          //onSelectSlot={(e) => console.log(e)}
          onSelectEvent={(e) => handleEventSelection(e)}
          components={{
            eventWrapper: EventWrapper,
            // event: Event,
          }}
          localizer={localizer}
          events={allEvents}
          startAccessor="start"
          endAccessor="end"
          // defaultDate={new Date(2018, 3, 27)}
          min={new Date(0, 0, 0, 7, 0, 0)}
          max={new Date(0, 0, 0, 21, 0, 0)}
          style={{ height: 500, margin: "50px" }}
        />
      </CacheProvider>
    </div>
  );
};

export default CalendarComp;
