import "./footer.scss";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Logo from "../../images/navLogo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import InstagramIcon from "@mui/icons-material/Instagram";
import React from "react";

const Footer = () => {
  return (
    <div>
      <Paper
        sx={{ borderTopRightRadius: "100px", borderTopLeftRadius: "100px" }}
        elevation={3}
      >
        <Box
          sx={{
            // width: "100%",
            // position: "fixed",
            // left: 0,
            // bottom: 0,
            // backgroundColor: "gray",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            padding: 1,
            // backgroundColor: "#b29f93",
          }}
        >
          <img src={Logo} />
          <div>
            <FacebookIcon
              id="facebook"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/Tal-Lopez-nails-112425427219157",
                  "_BLANK"
                )
              }
              sx={{ margin: 1, cursor: "pointer" }}
            />

            <InstagramIcon
              id="instagram"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/tal_lopez_nails",
                  "_BLANK"
                )
              }
              sx={{ margin: 1, cursor: "pointer" }}
            />
            <WhatsAppIcon
              id="whatsApp"
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send/?phone=972509731118&",
                  "_BLANK"
                )
              }
              sx={{ margin: 1, cursor: "pointer" }}
            />
            {/* <CallIcon sx={{ margin: 1 }} /> */}
          </div>
          {/* Tal Lopez 2020 */}
        </Box>
      </Paper>
    </div>
  );
};

export default Footer;
