import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box } from "@mui/material";
import "./imgList.scss";
import { styled } from "@mui/material/styles";
import work1 from "../../images/works/work1.png";
import work2 from "../../images/works/work2.png";
import work3 from "../../images/works/work3.png";
import work4 from "../../images/works/work4.png";
import work5 from "../../images/works/work5.png";
import work6 from "../../images/works/work6.png";
import work7 from "../../images/works/work7.png";
import work8 from "../../images/works/work8.png";
import work10 from "../../images/works/work10.png";
import work11 from "../../images/works/work11.png";
import work12 from "../../images/works/work12.png";
import work13 from "../../images/works/work13.png";
import work14 from "../../images/works/work14.png";
import work15 from "../../images/works/work15.png";
import work16 from "../../images/works/work16.png";
import work17 from "../../images/works/work17.png";
import work18 from "../../images/works/work18.png";
import work19 from "../../images/works/work19.png";
import work20 from "../../images/works/work20.png";
import work21 from "../../images/works/work21.png";
import work22 from "../../images/works/work22.png";
import work23 from "../../images/works/work23.png";
import work24 from "../../images/works/work24.png";
import work25 from "../../images/works/work25.png";
import work26 from "../../images/works/work26.png";
import work27 from "../../images/works/work27.png";
function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

export default function QuiltedImageList() {
  const ImageListT = styled(ImageList)(({ theme }) => ({
    width: "100% !important",

    [theme.breakpoints.down("lg")]: {
      width: "100% !important", // Overrides inline-style
    },

    [theme.breakpoints.down("sm")]: {
      width: "100% !important", // Overrides inline-style
    },
    // "&:hover, &.Mui-focusVisible": {
    //   zIndex: 1,
    //   "& .MuiImageBackdrop-root": {
    //     opacity: 0.15,
    //   },
    //   "& .MuiImageMarked-root": {
    //     opacity: 0,
    //   },
    //   "& .MuiTypography-root": {
    //     border: "4px solid currentColor",
    //   },
    // },
  }));

  return (
    <Box className="containerImgList">
      <Box className="subContainerImgList">
        <span id="gallery"></span>
        <Box className="containerTitleImgList">
          <h1 id="titleGallery">גלריית תמונות</h1>
          <span className="borderTitle"></span>
        </Box>

        <ImageListT
          sx={
            {
              // border: "5px solid #b29f93",
              // backgroundColor: "#b29f93",
              // width: "90%",
            }
          }
          variant="quilted"
          cols={4}
          // rowHeight={200}
        >
          {itemData.map((item) => (
            <ImageListItem
              key={item.img}
              cols={item.cols || 1}
              rows={item.rows || 1}
            >
              <img
                {...srcset(item.img, 121, item.rows, item.cols)}
                alt={item.title}
                loading="lazy"
              />
            </ImageListItem>
          ))}
        </ImageListT>
      </Box>
    </Box>
  );
}

const itemData = [
  {
    img: work3,
    title: "workNail",
    rows: 2,
    cols: 2,
  },
  {
    img: work27,
    title: "workNail",
  },
  {
    img: work5,
    title: "workNail",
  },
  {
    img: work11,
    title: "workNail",
    rows: 2,
    cols: 2,
  },
  {
    img: work8,
    title: "workNail",
  },
  {
    img: work6,
    title: "workNail",
  },

  {
    img: work12,
    title: "workNail",
    rows: 2,
    cols: 2,
  },
  {
    img: work14,
    title: "workNail",
  },
  {
    img: work4,
    title: "workNail",
  },
  {
    img: work13,
    title: "workNail",
    rows: 2,
    cols: 2,
  },
  {
    img: work10,
    title: "workNail",
  },
  {
    img: work7,
    title: "workNail",
  },

  {
    img: work15,
    title: "workNail",
    rows: 2,
    cols: 2,
  },
  {
    img: work16,
    title: "workNail",
  },
  {
    img: work17,
    title: "workNail",
  },
  {
    img: work26,
    title: "workNail",
    rows: 2,
    cols: 2,
  },
  {
    img: work21,
    title: "workNail",
  },
  {
    img: work18,
    title: "workNail",
  },

  {
    img: work22,
    title: "workNail",
    rows: 2,
    cols: 2,
  },
  {
    img: work24,
    title: "workNail",
  },
  {
    img: work23,
    title: "workNail",
  },
  {
    img: work19,
    title: "workNail",
    rows: 2,
    cols: 2,
  },
  {
    img: work25,
    title: "workNail",
  },
  {
    img: work20,
    title: "workNail",
  },
];
