import Footer from "../footer/footer";

const DataPolicy = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          direction: "ltr !important",
          marginTop: "15%",
          lineHeight: 2,
          width: "70%",
          textAlign: "right",
        }}
      >
        <h1 style={{ marginBottom: "4%" }}>מדיניות הפרטיות </h1>
        <ol style={{ direction: "rtl" }}>
          <b>
            איסוף פרטים אישיים
            <br />
            <span>
              יתכן שהסוגים הבאים של פרטים אישיים ייאספו, יאוחסנו, ויעשה בהם
              שימוש:
            </span>
          </b>

          <li>
            הפרטיות של המבקרים באתר שלנו חשובה לנו מאוד, ואנחנו מחויבים לשמירה
            עליה. המדיניות הזו מסבירה מה נעשה עם הפרטים האישיים שלכם.
          </li>

          <li>
            מידע על הביקורים והשימוש שלכם באתר, כולל מקור ההפניה, אורך הביקור,
            צפיות בעמודים, ונתיבי המעבר שלכם באתר
          </li>
          <li>
            מידע, כמו למשל השם וכתובת הדואר האלקטרוני שלכם, שאתם מזינים על מנת
            ליצור מנוי להודעות הדואר האלקטרוני ו/או הניוזלטר שלנו
          </li>
          <li>המידע שאתם מזינים בזמן השימוש בשירותים באתר שלנו</li>
          <li>
            מידע שנוצר בזמן השימוש באתר שלנו, כולל מתי, כמה, ובאילו נסיבות אתם
            משתמשים בו
          </li>
          <li>
            מידע שקשור לכל דבר שאתם רוכשים, שירותים בהם אתם משתמשים, או העברות
            שאתם מבצעים באתר שלנו, הכולל את השם שלכם, הכתובת, מספר הטלפון, כתובת
            הדואר האלקטרוני
          </li>
          <li>
            מידע שנכלל במסרים שאתם שולחים לנו בדואר האלקטרוני או באמצעות האתר
            שלנו, כולל תוכן המסר והמטא-דאתה שלו
          </li>
          <li>כל סוג אחר של פרטים אישיים שאתם שולחים אלינו</li>

          <br />
          <span>
            לפני שתחשפו בפנינו את הפרטים האישיים של אדם אחר, עליכם לבקש את הסכמת
            האדם הזה גם עבור השיתוף וגם עבור העיבוד של אותם הפרטים האישיים לפי
            מדיניות זו
          </span>
          <br />
          <b>שימוש בפרטים האישיים שלכם</b>
          <br />

          <span>
            בפרטים אישיים שנמסרו לנו דרך האתר שלנו ייעשה שימוש למטרות שצוינו
            במדיניות זו, או בעמודים הרלוונטיים שבאתר. ייתכן שנשתמש בפרטים
            האישיים שלכם למטרות הבאות:
          </span>
          <br />
          <br />

          <li>ניהול האתר והעסק שלנו</li>
          <li>התאמה האישית של האתר עבורכם</li>
          <li>לאפשר לכם להשתמש בשירותים הזמינים באתר שלנו</li>
          <li>לשלוח לכם הודעות מסחריות שאינן שיווקיות</li>
          <li>שלוח לכם התראות בדואר אלקטרוני שביקשתם באופן ספציפי</li>
          <li>
            טיפול בבירורים ותלונות שהוגשו על ידכם או נוגעות אליכם וקשורות לאתר
            שלנו
          </li>
          <li>לשמור על האתר שלנו מאובטח ולמנוע הונאה</li>
          <li>
            אימות של היענות לתנאי השירות המכתיבים את אופן השימוש באתר (כולל
            ניטור של הודעות פרטיות שנשלחו דרך שירות ההודעות הפרטיות של האתר
            שלנו);
          </li>
          <li>ושימושים אחרים</li>

          <span>
            אנחנו לא נספק את הפרטים האישיים שלכם ללא הסכמתכם לכל צד ג’ שהוא,
            לצורך השיווק הישיר שלו, או של כל צד ג’ אחר
          </span>

          <br />
          <br />
          <b>אבטחת הפרטים האישיים שלכם</b>

          <br />
          <br />
          <li>
            אנחנו ננקוט משנה זהירות ארגוני וטכני סביר על מנת למנוע את האבדן,
            השימוש לרעה, או השינוי של הפרטים האישיים שלכם.
          </li>
          <li>
            אנחנו נאחסן את כל הפרטים האישיים שאתם מספקים בשרתים מאובטחים
            (המוגנים בסיסמא ובחומת אש).
          </li>
          <li>
            אתם מכירים בכך שהשידור של מידע על פני האינטרנט אינו מאובטח מעצם
            היותו, ואנחנו ללא יכולים להבטיח שנתונים שנשלחים דרך האינטרנט יהיו
            מאובטחים.
          </li>
        </ol>
      </div>

      <div style={{ width: "100%" }}>
        <Footer />
      </div>
    </div>
  );
};

export default DataPolicy;
