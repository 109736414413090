import {
  Box,
  CircularProgress,
  IconButton,
  useMediaQuery,
} from "@mui/material";

import BackspaceIcon from "@mui/icons-material/Backspace";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const ButtonsCustomers = ({
  setCheckAddCustomer,
  setAddClient,
  checkAddCustomer,
}) => {
  const matches = useMediaQuery("(max-width:500px)");

  return (
    <div className="containerButtonsControl">
      <span className="heart-shape"></span>

      <h1
        id="titleNewClient"
        style={{ textAlign: "center", marginTop: "15px" }}
      >
        {!checkAddCustomer ? "קביעת תור" : "הוספת לקוחה"}
      </h1>

      <span className="iconAddCustomer">
        <IconButton
          onClick={() => setCheckAddCustomer(true)}
          // color="secondary"
          sx={{ color: "white" }}
          component="label"
        >
          <PersonAddIcon
            sx={{
              backgroundColor: "#9b27b0ab",
              padding: matches ? "5px" : "10px",
              height: matches ? "40px" : "80px",
              width: matches ? "50px" : "80px",
              borderTopRightRadius: "40px",
              borderBottomRightRadius: "40px",
              border: "3px solid #9C27B0",
            }}
            fontSize="large"
          />
        </IconButton>
      </span>
      {checkAddCustomer && (
        <span className="iconBackAddCustomer">
          <IconButton
            onClick={() =>
              setCheckAddCustomer(false) + setAddClient({ name: "", phone: "" })
            }
            // color="secondary"
            sx={{ color: "white" }}
            component="label"
          >
            <BackspaceIcon
              sx={{
                backgroundColor: "#9b27b0ab",

                padding: matches ? "5px" : "10px",
                height: matches ? "40px" : "80px",
                width: matches ? "50px" : "80px",
                borderTopRightRadius: "40px",
                borderBottomRightRadius: "40px",
                border: "3px solid #9C27B0",
              }}
              fontSize="large"
            />
          </IconButton>
        </span>
      )}
    </div>
  );
};

export default ButtonsCustomers;
