import "./nav.scss";
import * as React from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import Logo from "../../images/navLogo.png";

import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import useMediaQuery from "@mui/material/useMediaQuery";
const Nav = (props) => {
  const [value, setValue] = React.useState(0);
  const matches = useMediaQuery("(max-width:600px)");
  const checkLocation = props.location.pathname;

  React.useEffect(() => {
    const checkLocation = props.location.hash;

    if (checkLocation == "") {
      return setValue(0);
    }

    if (checkLocation == "#products") {
      return setValue(1);
    }

    if (checkLocation == "#gallery") {
      return setValue(2);
    }

    if (checkLocation == "#contact") {
      return setValue(3);
    }

    if (checkLocation == "#contact2") {
      return setValue(3);
    }
  }, [value, props, checkLocation]);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const theme = createTheme({
    palette: {
      primary: {
        light: "#757ce8",
        main: "#3f50b5",
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "rgb(128, 0, 111)",
        main: "#392613",
        dark: "rgb(128, 0, 111)",
        contrastText: "rgb(128, 0, 111)",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box
        id="containerNav"
        sx={{
          width: "100%",
          position: checkLocation == "/admin" ? null : "fixed",
          top: -1,
          zIndex: 2,
        }}
      >
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
          <img src={Logo} alt="" />
        </Box> */}
        <Box
          sx={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
        >
          <FacebookIcon
            id="facebook"
            onClick={() =>
              window.open(
                "https://www.facebook.com/Tal-Lopez-nails-112425427219157",
                "_BLANK"
              )
            }
            sx={{ margin: 1, cursor: "pointer" }}
          />

          <InstagramIcon
            id="instagram"
            onClick={() =>
              window.open("https://www.instagram.com/tal_lopez_nails", "_BLANK")
            }
            sx={{ margin: 1, cursor: "pointer" }}
          />
          <WhatsAppIcon
            id="whatsApp"
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send/?phone=972509731118&",
                "_BLANK"
              )
            }
            sx={{ margin: 1, cursor: "pointer" }}
          />
        </Box>

        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Tabs
              dir="rtl"
              indicatorColor="secondary"
              value={value}
              // onChange={handleChange}
              centered
            >
              <Tab href="/#" className="tabs" label="ראשי"></Tab>

              <Tab href="/#products" className="tabs" label="שירותים" />
              <Tab href="/#gallery" className="tabs" label="גלרייה" />
              {/* {matches ? (
                <Tab
                  onClick={() =>
                    document.getElementById("contact").scrollIntoView()
                  }
                  href="#contact2"
                  className="tabs"
                  label="יצירת קשר"
                />
              ) : (
                <Tab
                  // sx={{ zIndex: 999999 }}
                  href="#contact"
                  className="tabs"
                  label="יצירת קשר"
                />
              )} */}
              <Tab href="/#contact" className="tabs" label="יצירת קשר" />
            </Tabs>
          </Typography>
        </Toolbar>
      </Box>
    </ThemeProvider>
  );
};

export default Nav;
