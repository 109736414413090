import "./products.scss";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import BodyWax from "../../images/products/BodyWax.png";
import Laster from "../../images/products/laser.png";
import nail from "../../images/products/nail.png";
import pedicure from "../../images/products/pedicure.png";
import manicure from "../../images/products/manicure.png";
import eyebrows from "../../images/products/eyebrows.png";

import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import React, { useState, useEffect } from "react";
import CallIcon from "@mui/icons-material/Call";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
const Products = () => {
  const [hoverCheck, setHoverCheck] = useState(false);
  const [hoverCheck2, setHoverCheck2] = useState(false);
  const [hoverCheck3, setHoverCheck3] = useState(false);

  // עיצוב גבות
  const [hoverCheck4, setHoverCheck4] = useState(false);
  // מניקור
  const [hoverCheck5, setHoverCheck5] = useState(false);
  // פדיקור
  const [hoverCheck6, setHoverCheck6] = useState(false);

  useEffect(() => {}, [
    hoverCheck,
    hoverCheck2,
    hoverCheck3,
    hoverCheck4,
    hoverCheck5,
    hoverCheck6,
  ]);

  const images = [
    {
      url: Laster,
      title: "הסרת שיער בלייזר",
      width: "32%",
    },

    {
      url: nail,
      title: "בניית ציפורנים/לק ג'ל",
      width: "32%",
    },
    {
      url: manicure,
      title: "מניקור",
      width: "32%",
    },
    {
      url: BodyWax,
      title: "שעוות",
      width: "32%",
    },
    {
      url: eyebrows,
      title: "עיצוב גבות",
      width: "32%",
    },
    {
      url: pedicure,
      title: "פדיקור",
      width: "32%",
    },
  ];

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: "relative",
    height: 200,
    border: "5px solid white",
    borderRadius: "35px",
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100% !important", // Overrides inline-style
      height: 300,
      marginBottom: 10,
    },

    // "&:hover, &.Mui-focusVisible": {
    //   zIndex: 1,
    //   "& .MuiImageBackdrop-root": {
    //     opacity: 0.15,
    //   },
    //   "& .MuiImageMarked-root": {
    //     opacity: 0,
    //   },
    //   "& .MuiTypography-root": {
    //     width: "180px",
    //     border: "4px solid currentColor",
    //     backgroundColor: "black",
    //     opacity: "0.7",
    //   },
    // },
  }));

  const ImageSrc = styled("span")({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
    borderRadius: "30px",
  });

  const Image = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    color: theme.palette.common.white,
  }));

  const ImageBackdrop = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
    borderRadius: "30px",
  }));

  const ImageMarked = styled("span")(({ theme }) => ({
    height: 3,
    width: 18,
    // backgroundColor: theme.palette.common.white,
    backgroundColor: "white",
    position: "absolute",
    bottom: 70,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),

    [theme.breakpoints.down("sm")]: {
      bottom: 120,
    },
  }));

  return (
    <>
      <Box
        id="containerProducts"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
          // backGroundImage: `url(../../images/bg.png)`,
          // marginTop: "100px",
        }}
      >
        <Box
          className="subContainerProducts"
          sx={{
            marginTop: "100px",
            marginBottom: "100px",
            position: "relative",
          }}
        >
          <span id="products"></span>
          <Box className="containerTitleProduct">
            {/* <a id="titleProducts">קבעי עכשיו</a> */}
            {/* <span className="borderTitle"></span> */}
          </Box>
        </Box>
        <Box
          className="subContainerProcucts2"
          // sx={{
          //   display: "flex",
          //   flexWrap: "wrap",
          //   justifyContent: "space-evenly",
          //   minWidth: 300,
          //   width: "95%",
          // }}
        >
          {images.map((image) => (
            <ImageButton
              focusRipple
              key={image.title}
              // onMouseEnter={() => {
              //   if (image.title === "שעוות") {
              //     return setHoverCheck(true);
              //   }
              //   if (image.title === "לק ג'ל") {
              //     return setHoverCheck2(true);
              //   }
              //   if (image.title === "בניית ציפורניים") {
              //     return setHoverCheck3(true);
              //   }

              //   if (image.title === "עיצוב גבות") {
              //     return setHoverCheck4(true);
              //   }
              //   if (image.title === "מניקור") {
              //     return setHoverCheck5(true);
              //   }
              //   if (image.title === "פדיקור") {
              //     return setHoverCheck6(true);
              //   }
              // }}
              // onMouseLeave={() => {
              //   if (image.title === "שעוות") {
              //     return setHoverCheck(false);
              //   }
              //   if (image.title === "לק ג'ל") {
              //     return setHoverCheck2(false);
              //   }
              //   if (image.title === "בניית ציפורניים") {
              //     return setHoverCheck3(false);
              //   }
              //   if (image.title === "עיצוב גבות") {
              //     return setHoverCheck4(false);
              //   }
              //   if (image.title === "מניקור") {
              //     return setHoverCheck5(false);
              //   }
              //   if (image.title === "פדיקור") {
              //     return setHoverCheck6(false);
              //   }
              // }}
              style={{
                width: image.width,
              }}
            >
              <ImageSrc
                style={{ backgroundImage: `url(${image.url})` }}
                alt={image.title}
              />
              <ImageBackdrop className="MuiImageBackdrop-root" />

              <Image>
                <Typography
                  component="span"
                  variant="subtitle1"
                  sx={{
                    position: "relative",
                    p: 4,
                    pt: 2,
                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                  }}
                >
                  <Box
                    className="subInContainerProcucts2"
                    // sx={{
                    //   display: "flex",
                    //   justifyContent: "center",
                    //   alignItems: "center",
                    //   flexDirection: "column",
                    // }}
                  >
                    {/* <div>
                 

                      {(hoverCheck && image.title === "שעוות") ||
                      (hoverCheck2 && image.title === "לק ג'ל") ||
                      (hoverCheck3 && image.title === "בניית ציפורניים") ||
                      (hoverCheck4 && image.title === "עיצוב גבות") ||
                      (hoverCheck5 && image.title === "מניקור") ||
                      (hoverCheck6 && image.title === "פדיקור")
                        ? null
                        : image.title}
                    </div> */}

                    <div>
                      <div className="containerProductsTitels">
                        <div className="productsTitles">{image.title}</div>
                      </div>

                      {/* <ImageMarked
                        color="inherit"
                        className="MuiImageMarked-root"
                      /> */}
                      <span className="borderTitleProducts"></span>
                    </div>
                    {/* {hoverCheck && image.title === "שעוות" ? ( */}
                    {image.title === "שעוות" ? (
                      <Box
                        className="subInContainerProcucts2Icons"

                        // sx={
                        //   hoverCheck
                        //     ? {
                        //         // opacity: 0.15,
                        //         width: "180px",
                        //         height: "90px",
                        //         border: "4px solid currentColor",
                        //         backgroundColor: "black",
                        //         opacity: "0.7",
                        //       }
                        //     : null
                        // }
                      >
                        {/* <div> {image.title} </div> */}
                        <p>שעוות גוף פנים, רגליים, ידיים ועוד </p>
                        {/* <WhatsAppIcon
                          onClick={() =>
                            window.open(
                              `https://wa.me/972509731118?text=היי,+אני+צריכה+שעוות+זמינה+?`,
                              "_blank"
                            )
                          }
                        />
                        <CallIcon
                          sx={{ color: "white" }}
                          onClick={() =>
                            (window.location = "tel:+972509731118")
                          }
                        /> */}
                      </Box>
                    ) : null}

                    {image.title === "הסרת שיער בלייזר" ? (
                      <Box
                        className="subInContainerProcucts2Icons"
                        // sx={
                        //   hoverCheck2
                        //     ? {
                        //         // opacity: 0.15,
                        //         width: "180px",
                        //         height: "90px",
                        //         border: "4px solid currentColor",
                        //         backgroundColor: "black",
                        //         opacity: "0.7",
                        //       }
                        //     : null
                        // }
                      >
                        {/* <div> {image.title} </div> */}
                        <p>הסרת שיער בטכנולוגיה המתקדמת שיש</p>
                        {/* <p>
                          ציפוי קוסמטי הנמרח על ציפורני אצבעות הידיים לשם ייפוי
                          וקישוט
                        </p> */}

                        {/* <WhatsAppIcon
                          onClick={() =>
                            window.open(
                              `https://wa.me/972509731118?text=היי,+אני+צריכה+לק+ג'ל+זמינה+?`,
                              "_blank"
                            )
                          }
                        />
                        <CallIcon
                          sx={{ color: "white" }}
                          onClick={() =>
                            (window.location = "tel:+972509731118")
                          }
                        /> */}
                      </Box>
                    ) : null}

                    {image.title === "בניית ציפורנים/לק ג'ל" ? (
                      <Box
                        className="subInContainerProcucts2Icons"
                        // sx={
                        //   hoverCheck3
                        //     ? {
                        //         // opacity: 0.15,
                        //         width: "180px",
                        //         height: "90px",
                        //         border: "4px solid currentColor",
                        //         backgroundColor: "black",
                        //         opacity: "0.7",
                        //       }
                        //     : null
                        // }
                      >
                        {/* <div> {image.title} </div> */}

                        <p>
                          -בניית ציפורניים בשיטת אקריל
                          <br />
                          -לק ג'ל מפנק הכולל מניקור מכשירי יסודי
                        </p>
                        {/* <WhatsAppIcon
                          onClick={() =>
                            window.open(
                              `https://wa.me/972509731118?text=היי,+אני+צריכה+בניית+ציפורניים+זמינה+?`,
                              "_blank"
                            )
                          }
                        />
                        <CallIcon
                          sx={{ color: "white" }}
                          onClick={() =>
                            (window.location = "tel:+972509731118")
                          }
                        /> */}
                      </Box>
                    ) : null}

                    {image.title === "עיצוב גבות" ? (
                      <Box
                        className="subInContainerProcucts2Icons"
                        // sx={
                        //   hoverCheck4
                        //     ? {
                        //         // opacity: 0.15,
                        //         width: "180px",
                        //         height: "90px",
                        //         border: "4px solid currentColor",
                        //         backgroundColor: "black",
                        //         opacity: "0.7",
                        //       }
                        //     : null
                        // }
                      >
                        {/* <div> {image.title} </div> */}

                        {/* <p>גבות יפות הן מה שמגדיר את היופי של הפנים</p> */}
                        <p>עיצוב גבות מפנק שעווה / פינצטה</p>
                        {/* <WhatsAppIcon
                          onClick={() =>
                            window.open(
                              `https://wa.me/972509731118?text=היי,+אני+צריכה+עיצוב+גבות+זמינה+?`,
                              "_blank"
                            )
                          }
                        />
                        <CallIcon
                          sx={{ color: "white" }}
                          onClick={() =>
                            (window.location = "tel:+972509731118")
                          }
                        /> */}
                      </Box>
                    ) : null}

                    {image.title === "מניקור" ? (
                      <Box
                        className="subInContainerProcucts2Icons"
                        // sx={
                        //   hoverCheck5
                        //     ? {
                        //         // opacity: 0.15,
                        //         width: "180px",
                        //         height: "90px",
                        //         border: "4px solid currentColor",
                        //         backgroundColor: "black",
                        //         opacity: "0.7",
                        //       }
                        //     : null
                        // }
                      >
                        {/* <div> {image.title} </div> */}

                        <p>טיפול בעור שמסביב לציפורן ואף מריחת לק</p>
                        {/* <WhatsAppIcon
                          onClick={() =>
                            window.open(
                              `https://wa.me/972509731118?text=היי,+אני+צריכה+מניקור+זמינה+?`,
                              "_blank"
                            )
                          }
                        />
                        <CallIcon
                          sx={{ color: "white" }}
                          onClick={() =>
                            (window.location = "tel:+972509731118")
                          }
                        /> */}
                      </Box>
                    ) : null}

                    {image.title === "פדיקור" ? (
                      <Box
                        className="subInContainerProcucts2Icons"
                        // sx={
                        //   hoverCheck6
                        //     ? {
                        //         // opacity: 0.15,
                        //         width: "180px",
                        //         height: "90px",
                        //         border: "4px solid currentColor",
                        //         backgroundColor: "black",
                        //         opacity: "0.7",
                        //       }
                        //     : null
                        // }
                      >
                        {/* <div> {image.title} </div> */}
                        <p>טיפול קוסמטי של כפות הרגליים וציפורני הרגליים </p>
                        {/* <WhatsAppIcon
                          onClick={() =>
                            window.open(
                              `https://wa.me/972509731118?text=היי,+אני+צריכה+פדיקור+זמינה+?`,
                              "_blank"
                            )
                          }
                        />
                        <CallIcon
                          sx={{ color: "white" }}
                          onClick={() =>
                            (window.location = "tel:+972509731118")
                          }
                        /> */}
                      </Box>
                    ) : null}
                  </Box>
                </Typography>
              </Image>
            </ImageButton>
          ))}
          <div className="containerIconsProducts">
            <FacebookIcon
              onClick={() =>
                window.open("https://www.facebook.com/tal.assayag", "_BLANK")
              }
              sx={{ margin: 1, cursor: "pointer" }}
            />

            <InstagramIcon
              onClick={() =>
                window.open(
                  "https://www.instagram.com/tal_lopez_nails",
                  "_BLANK"
                )
              }
              sx={{ margin: 1, cursor: "pointer" }}
            />
            <WhatsAppIcon
              onClick={() =>
                window.open(
                  "https://api.whatsapp.com/send/?phone=972509731118&",
                  "_BLANK"
                )
              }
              sx={{ margin: 1, cursor: "pointer" }}
            />
            {/* <CallIcon sx={{ margin: 1 }} /> */}
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Products;
