import axios from "axios";
import toast from "react-hot-toast";
// import { toast } from "react-toastify";
axios.defaults.withCredentials = true;

// check is the server is running or another error //
// axios.interceptors.response.use(null, (error) => {
//   console.dir(error);
//   const { response } = error;

//   // if (!response) {
//   //   console.log(response);
//   //   toast("🤔 אופס משהו השתבש ... אנא נסי שנית");
//   //   // toast.error("Bad connection to server");
//   // }

//   //   if (response && response.status >= 403) {
//   //     // toast.error("An unexpected error occurred");
//   //     // window.location.reload();
//   //     window.location = "/";
//   //   }
//   // if the server fail return reject if i not return reject to front the front cant Know if we have problem withe the server(Back-end) //
//   return Promise.reject(error);
// });

// const instance = axios.create({
//   withCredentials: true,
//   baseURL: endpoint1,
// });

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  patch: axios.patch,
  delete: axios.delete,
  all: axios.all,
  spread: axios.spread,
};

export default http;
