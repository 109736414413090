import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { adminPanelContext } from "../../../services/conext/adminPanel";
import toast from "react-hot-toast";

export default function AlertDialog({ editEvent, setOpen, setEventSelect }) {
  const [open, setClose] = React.useState(false);
  const { deleteappointment, setAllClients } =
    React.useContext(adminPanelContext);

  const handleClickOpen = () => {
    setClose(true);
  };

  const handleClose = () => {
    setClose(false);
  };

  React.useEffect(() => {}, [editEvent]);

  const handleSubmit = async () => {
    const { id, idService } = editEvent;

    console.log(id, idService);

    const { status } = await deleteappointment({ id, idService });

    if (status === 200) {
      toast.success("התור נמחק בהצלחה");
      setEventSelect({
        name: "",
        id: "",
        title: "",
        start: "",
        end: "",
      });
      setOpen(false);
      setClose(false);
      // setAllClients([]);
    }
  };

  return (
    <div>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <DeleteIcon sx={{ color: "#7b1fa2" }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"מחיקת תור"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            האם את בטוחה שאת רוצה למחוק את התור הזה ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ביטול</Button>
          <Button onClick={handleSubmit} autoFocus>
            אישור
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
