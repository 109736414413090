import * as React from "react";
import DeleteEvent from "../Delete/deleteEvent";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDateTimePicker } from "@mui/x-date-pickers";

import { CacheProvider } from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import toast from "react-hot-toast";
import { LoadingButton } from "@mui/lab";
import authHeader from "../../../services/Auth/auth-header";

export default function Edit({
  eventSelect,
  editappointment,
  setAllEvents,
  setAllClients,
  openWindow,
  setEventSelect,
  getAllData,
  socket,
}) {
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });
  const timer = React.useRef(null);

  const services = ["בנייה באקריל", "לק ג'ל", "שעוות", "פדיקור", "מניקור"];
  const [open, setOpen] = React.useState(openWindow);
  const [loader, setLoader] = React.useState(false);
  const [deleteEvent, setDeleteEvent] = React.useState(false);

  const [editEvent, setEditEvent] = React.useState({
    name: "",
    service: "",
    id: "",
    title: "",
    start: "",
    end: "",
    onlyTitle: "",
  });

  const { name, service, id, idService, title, start, end, onlyTitle } =
    eventSelect;

  React.useEffect(() => {
    const test = async () => {
      await setEditEvent((prev) => {
        return {
          ...editEvent,
          name: eventSelect.name,
          id: eventSelect.id,
          idService: eventSelect.idService,
          title: eventSelect.onlyTitle,
          start: eventSelect.start,
          end: eventSelect.end,
          // service: eventSelect.onlyTitle,
          // phone: eventSelect.phone,
        };
      });
    };
    test();

    return () => {
      clearTimeout(timer.current);
    };
  }, [name, service, id, title, start, end, onlyTitle, idService]);

  const matches = useMediaQuery("(max-width:500px)");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setEventSelect({
      name: "",
      id: "",
      title: "",
      start: "",
      end: "",
    });
    setOpen(false);
  };

  async function editAppointment() {
    setLoader(true);
    authHeader();
    // console.log(editEvent);

    try {
      const response = await editappointment({
        id: editEvent.id,
        idService: editEvent.idService,
        title: editEvent.title,
        start: editEvent.start,
        end: editEvent.end,
      });

      const { status } = response;

      if (status === 200) {
        // setAllClients([]);
        setLoader(false);
        setOpen(false);
        // setEventSelect({
        //   name: "",
        //   id: "",
        //   title: "",
        //   start: "",
        //   end: "",
        // });
        toast.success("עודכן בהצלחה");
      }
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  }

  return (
    <Grid
      container
      spacing={5}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      {/* <IconButton onClick={handleClickOpen}>
        <NotListedLocationIcon fontSize="large" sx={{ color: "#9b27b0ab" }} />
      </IconButton> */}

      {/* <Button variant="outlined" onClick={handleClickOpen}>
      // </Button> */}
      <Box>
        <Dialog
          fullWidth
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"שינוי תור קיים"}</DialogTitle>

          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <LocalizationProvider
                adapterLocale="he"
                dateAdapter={AdapterDayjs}
              >
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  // sx={{ minHeight: "50vh", minWidth: "110%" }}
                >
                  <FormControl fullWidth sx={{ margin: "5px" }}>
                    <TextField
                      disabled={true}
                      // className="inputCustomer"
                      sx={{ marginBottom: 2 }}
                      id="outlined-multiline-flexible"
                      label="שם"
                      value={editEvent.name}
                      // onChange={(e) =>
                      //   setAddClient({ ...addClient, name: e.target.value })
                      // }
                    />
                    {/* <FormControl sx={{ minWidth: "85%" }}> */}
                    <FormControl>
                      <InputLabel
                        // sx={{ fontSize: "12px", marginBottom: 2 }}
                        dir="rtl"
                        id="demo-simple-select-label"
                      >
                        סוג טיפול
                      </InputLabel>
                      <Select
                        sx={{
                          marginBottom: 2,
                          // width: "99%",
                          // flexDirection: "column",
                          // alignItems: "center",
                          // justifyContent: "center",
                        }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={editEvent.title}
                        label="סוג טיפול"
                        onChange={(e) =>
                          setEditEvent({
                            ...editEvent,
                            title: e.target.value,
                          })
                        }
                      >
                        {services.map((service) => {
                          return (
                            <MenuItem key={service} value={service}>
                              {service}
                            </MenuItem>
                          );
                        })}

                        {/* <MenuItem value={services[0]}>בנייה באקריל</MenuItem>
                        <MenuItem value={services[1]}>לק ג'ל</MenuItem>
                        <MenuItem value={services[2]}>שעוות</MenuItem>
                        <MenuItem value={services[3]}>פדיקור</MenuItem>
                        <MenuItem value={services[4]}>מניקור</MenuItem> */}
                      </Select>
                    </FormControl>
                    <MobileDateTimePicker
                      // sx={{ minWidth: "120%" }}
                      ampm={false}
                      // className="inputAddEvent"
                      renderInput={(params) => (
                        <TextField sx={{ marginBottom: 2 }} {...params} />
                      )}
                      label="התחלה"
                      value={editEvent.start}
                      onChange={(newValue) => {
                        // setStart(newValue);
                        setEditEvent({ ...editEvent, start: newValue.$d });
                        // setNewEvent({ ...newEvent, start: testVal });
                      }}
                      // minDateTime={dayjs()}
                    />
                    <MobileDateTimePicker
                      ampm={false}
                      // className="inputAddEvent"
                      renderInput={(params) => (
                        <TextField sx={{ marginBottom: 1 }} {...params} />
                      )}
                      label="סיום"
                      value={editEvent.end}
                      onChange={(newValue) =>
                        setEditEvent({ ...editEvent, end: newValue.$d })
                      }
                    />
                    <Button
                      disabled={loader}
                      type="submit"
                      className={"buttonNewEvent"}
                      variant="contained"
                      size={"large"}
                      onClick={editAppointment}
                      sx={{
                        // height: !matches ? "55px" : "40px",
                        fontWeight: "bold",
                        // width: "85%",
                      }}
                      color="secondary"
                    >
                      אישור
                    </Button>
                  </FormControl>
                </Grid>
              </LocalizationProvider>
            </DialogContentText>
            {loader ? (
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  sx={{
                    color: "#392613",
                    marginTop: "50px",
                  }}
                />
              </div>
            ) : null}
          </DialogContent>

          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
                // direction: "rtl",
                // position: "relative",
              }}
            >
              <Box
              // sx={{
              //   display: "flex",
              //   justifyContent: "space-between",
              //   alignItems: "center",
              //   // position: "relative",
              // }}
              >
                <Button
                  sx={{ color: "#7b1fa2", fontWeight: "bold" }}
                  onClick={handleClose}
                >
                  סגירה
                </Button>
              </Box>
              <Box>
                <IconButton>
                  <DeleteEvent
                    setOpen={setOpen}
                    editEvent={editEvent}
                    setEventSelect={setEventSelect}
                  />
                </IconButton>
              </Box>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    </Grid>
  );
}
