import { Box } from "@mui/material";
import "./App.css";
import Dashboard from "./components/dashboard/dashboard";
import "bootstrap/dist/css/bootstrap.min.css";

import toast, { Toaster } from "react-hot-toast";
import React from "react";
import AdminPanelContext from "./services/conext/adminPanel";
// kick off the polyfill!

function App() {
  return (
    <div>
      <Box className="App">
        <Toaster position="bottom-center" reverseOrder={false} />

        <AdminPanelContext>
          <Dashboard />
        </AdminPanelContext>
      </Box>
    </div>
  );
}

export default App;
