import React, { useState, useEffect, useRef } from "react";
import toast from "react-hot-toast";
import http from "../http";
import { endpoint1, endpoint2 } from "../config";
import AbortController from "abort-controller";

export const adminPanelContext = React.createContext();

const AdminPanelContext = ({ children }) => {
  const abortController = useRef();
  const [user, setUser] = useState(null);
  const [allClients, setAllClients] = useState([]);
  // const [allClientsFromAPI, setAllClientsFromAPI] = useState([]);

  //   const allClientsFromAPI =

  // UseEffect(()=>{
  //   allClientsFromAPI, setAllClientsFromAPI
  // },[])

  const [allEvents, setAllEvents] = useState([]);
  const [eventSelect, setEventSelect] = useState({
    name: "",
    id: "",
    title: "",
    onlyTitle: "",
    start: "",
    end: "",
  });

  const addClientsToAutoCom = (clients) => {
    return setAllClients((prev) => [...prev, clients]);
  };

  const getUserLogin = (user) => {
    // console.log(user);

    // localStorage.setItem('jwtToken', user.token)

    return setUser(user);
  };

  // const [allEvents, setAllEvents] = useState([]);
  // const [allClients, setAllClients] = useState([]);

  const login = async ({ email, password }) => {
    return await http.post(`${endpoint1}/login`, {
      email,
      password,
      signal: abortController.current.signal,
    });
  };

  async function getAllData() {
    return await http.get(`${endpoint1}/getAllData`).then(async (response) => {
      return {
        data: response.data,
        signal: abortController.current.signal,
      };
    });
  }

  async function addAppointment({ id, title, start, end }) {
    return await http.post(`${endpoint1}/newappointment`, {
      id,
      title,
      start,
      end,
      signal: abortController.current.signal,
    });

    // return await allData();
  }

  async function editappointment({ id, idService, title, start, end }) {
    return await http.put(`${endpoint1}/editappointment`, {
      id,
      idService,
      title,
      start,
      end,
      signal: abortController.current.signal,
    });
  }

  async function deleteappointment({ id, idService }) {
    return await http.post(`${endpoint1}/delappointment`, {
      id,
      idService,
      signal: abortController.current.signal,
    });
  }

  async function createClient({ name, phone }) {
    return await http.post(`${endpoint1}/createClient`, {
      name,
      phone,
      signal: abortController.current.signal,
    });
  }

  useEffect(() => {
    abortController.current = new AbortController();

    return () => {
      if (abortController) {
        abortController.current.abort();
      }
    };
  }, []);

  /// ------ TOOLS ------ //

  return (
    <adminPanelContext.Provider
      value={{
        getAllData,
        addAppointment,
        createClient,
        editappointment,
        deleteappointment,
        login,
        getUserLogin,
        user,
        allClients,
        setAllClients,
        allEvents,
        setAllEvents,
        eventSelect,
        setEventSelect,
        // allEvents,
        // setAllEvents,
        // allClients,
        // setAllClients,
        // allData,
      }}
    >
      {children}
    </adminPanelContext.Provider>
  );
};

export default AdminPanelContext;
