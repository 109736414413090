import React, { useState, useContext } from "react";
import Event from "../Event/Event";
import moment from "moment";
// import moment from "moment-mini";

const EventWrapper = ({ event, children, onClick }) => {
  // const EventWrapper = (eventWrapper) => {

  const { title, className } = children.props;

  function randomColor(brightness) {
    function randomChannel(brightness) {
      var r = 255 - brightness;
      var n = 0 | (Math.random() * r + brightness);
      var s = n.toString(16);
      return s.length == 1 ? "0" + s : s;
    }
    return (
      "#" +
      randomChannel(brightness) +
      randomChannel(brightness) +
      randomChannel(brightness)
    );
  }

  const customClass = `${className} ${event.color}`;
  const hourStart = moment(event.start).hour();
  const hourStop = moment(event.end).hour();

  // סידור האיוונטים לפי 7 בבוקר עד 8 בלילה
  const gridRowStart = hourStart - 6;

  return (
    <div
      onClick={onClick}
      title={title}
      className={`${customClass} ${event.color}`}
      style={{ gridRow: `${gridRowStart} / span ${hourStop - hourStart}` }}
    >
      {children.props.children}
    </div>
  );
};

export default EventWrapper;
