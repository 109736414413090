import React, { useEffect, useContext, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  Link,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
// import { FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";

// Context

import { adminPanelContext } from "../../../services/conext/adminPanel";
import toast from "react-hot-toast";
import "./Login.scss";
// import http from "../../../services/http";
import axios from "axios";
import authHeader from "../../../services/Auth/auth-header";

const Login = ({ loader }) => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const { login, getUserLogin } = useContext(adminPanelContext);

  async function userLogin(e) {
    e.preventDefault();

    let reEmail = /\S+@\S+\.\S+/;

    const { email, password } = user;

    if (!email && !password) {
      return toast.error("אנא מלאי את השדות בבקשה");
    }

    if (email && !reEmail.test(email)) {
      return toast.error("אנא מלא/י מייל תקין בבקשה ");
    }

    // const handleSubmit = (e) => {
    //   e.preventDefault()
    // //   setErrors('')
    // //   axios.post('/login', {name:username,password:password})
    // //   .then(res=> {
    // //      localStorage.setItem('jwtToken', res.data.token)
    // //      axios.defaults.headers.common['Authorization'] =
    // //        'Bearer'+res.data.token
    // //      setUser({ auth:true, name: res.data.username })
    // //   })
    // //   .catch(err=>{
    // //      if(err.response){
    // //        if(err.response.status===401) setErrors('Invalid credentials')
    // //        else setErrors('Please try again.')
    // //   }
    // //      console.log(err)
    // //   })
    // // }

    await login({ email, password })
      .then((res) => {
        console.log(res.data);

        if (res.data == "error") {
          toast.error(
            <div style={{ direction: "rtl" }}>המשתמש או הסיסמא אינם נכונים</div>
          );
          return;
        }

        if (res.status === 200) {
          toast.success(
            <div style={{ direction: "rtl" }}>
              התחברת בהצלחה ברוכה הבאה חיים שלי ❤
            </div>
          );

          //עם הטוקן
          getUserLogin(res.data.user);

          localStorage.setItem("user", res.data.token);
          authHeader();
          // axios.defaults.headers.common["x-access-token"] = res.data.token;

          getUserLogin(res.data.user);
        }
      })
      .catch((err) => {
        if (err.response && err?.response.status === 401) {
          toast.error(
            <div style={{ direction: "rtl" }}>המשתמש או הסיסמא אינם נכונים</div>
          );
        }
      });

    // if (status === 200) {
    //   toast.success("התחברת בהצלחה :)");
    // }
  }

  return (
    <div>
      <div className="ContainerLogin">
        <div className="SubContainerLogin">
          <span className="heart-shape"></span>

          <div className="containerTitle">
            <h1
              id="titleNewClient"
              style={{ textAlign: "center", marginTop: "15px" }}
            >
              התחברות
            </h1>
          </div>
          {/* <form className="formLogin" onSubmit={userLogin}> */}
          <FormControl className="formLogin">
            <TextField
              className="inputLogin"
              // sx={{ width: "20%" }}
              id="outlined-multiline-flexible"
              label="מייל"
              type="email"
              value={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
            <TextField
              className="inputLogin"
              // sx={{ width: "20%" }}
              id="outlined-multiline-flexible"
              label="סיסמא"
              type="password"
              value={user.password}
              onChange={(e) => setUser({ ...user, password: e.target.value })}
            />
            <Button
              onClick={userLogin}
              disabled={loader}
              type="submit"
              // className={!checkAddCustomer ? "buttonNewEvent" : "buttonNewClient"}
              variant="contained"
              // size={matches ? "large" : "large"}
              // onClick={!checkAddCustomer ? handleAddEvent : handleAddClient}
              sx={{
                // height: !matches ? "55px" : "40px",
                fontWeight: "bold",
              }}
              color="secondary"
            >
              התחברות
              {/* {!checkAddCustomer ? "קבעי תור" : " הוספת לקוחה"} */}
            </Button>
          </FormControl>
        </div>

        {loader ? (
          <CircularProgress sx={{ color: "#392613", marginTop: "50px" }} />
        ) : null}
      </div>
    </div>
  );
};

export default Login;
