import React, { useState, useEffect, useContext, useRef } from "react";
import { momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/he";

import "react-big-calendar/lib/css/react-big-calendar.css";
import Logo from "../../images/navLogo.png";
import Footer from "../footer/footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { adminPanelContext } from "../../services/conext/adminPanel";
import dayjs from "dayjs";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import "./Customers.scss";

// Toast
import toast from "react-hot-toast";

// Mui
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import BackspaceIcon from "@mui/icons-material/Backspace";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import {
  Box,
  CircularProgress,
  IconButton,
  useMediaQuery,
} from "@mui/material";

import CalendarComp from "./CalendarComp/CalendarComp";
import Login from "./Login/Login";
import authHeader from "../../services/Auth/auth-header";

import NewAppointment from "./newAppointment/newAppointment";
import NewCustomer from "./newCustomer/newCustomer";
import ButtonsCustomers from "./ButtonsCustomers/ButtonsCustomers";

function Customers() {
  const matches = useMediaQuery("(max-width:500px)");

  const [loader, setLoader] = useState(false);
  const [checkAddCustomer, setCheckAddCustomer] = useState(false);
  const [addClient, setAddClient] = useState({
    name: "",
    phone: "",
  });

  const [newEvent, setNewEvent] = useState({
    id: "",
    title: "",
    start: dayjs(),
    end: dayjs(),
  });

  const { allClients, addAppointment, createClient, user, setAllClients } =
    useContext(adminPanelContext);
  // console.log(user);

  useEffect(() => {
    // console.log(user);

    window.scrollTo(0, 0);
  }, []);

  async function handleAddEvent(e) {
    e.preventDefault();

    if (newEvent.id < 2) {
      return toast.error("אנא בחר/י את הלקוחה בבקשה");
    }

    if (newEvent.title < 2) {
      return toast.error("אנא מלא/י את סוג הטיפול בבקשה");
    }

    if (newEvent.start < 2) {
      return toast.error("אנא מלא/י את תאריך ההתחלה בבקשה");
    }

    if (newEvent.end < 2) {
      return toast.error("אנא מלא/י את תאריך הסיום בבקשה");
    }

    if (
      moment(newEvent.start).date() != moment(newEvent.end).date() ||
      moment(newEvent.start).month() != moment(newEvent.end).month()
      // && moment(newEvent.start).month() != moment(newEvent.end).month()
    ) {
      return toast.error("קביעת תור אפשרית רק לאותו היום אנא שני תאריך סיום");
    }

    try {
      authHeader();
      setLoader(true);
      const { status, data } = await addAppointment(newEvent);

      if (status === 200) {
        // setNewEvent({
        //  ...newEvent,
        //  id: "",
        //  title: "",
        //});

        toast.success("התור נקבע בהצלחה");

        setLoader(false);
        // setAllClients([]);
        // setEventSelect({});
      }
    } catch (err) {
      setLoader(false);
      toast.error(" כנראה שאחד התאריכים ריקים אנא מלאי שנית");
      console.log(err);
    }
  }

  async function handleAddClient(e) {
    e.preventDefault();

    let rePhone = [
      /^[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}$/,
    ];

    if (!addClient.name && !addClient.phone) {
      return toast.error("אנא מלא/י את כל השדות בבקשה ");
    }

    if (addClient.name.length < 2) {
      return toast.error("אנא מלא/י את השם של הלקוחה בבקשה ");
    }

    if (addClient.phone <= 2) {
      return toast.error("אנא מלא/י את הפלאפון של הלקוחה בבקשה ");
    }

    if (!rePhone[0].test(addClient.phone)) {
      return toast.error("אנא מלא/י את מספר הפלאפון באופן תקין ");
    }

    try {
      authHeader();
      setLoader(true);
      const { status } = await createClient({
        name: addClient.name,
        phone: addClient.phone,
      });

      if (status === 200) {
        setAddClient({ name: "", phone: "" });
        toast.success("הלקוחה נוספה בהצלחה ❤");
        setLoader(false);
      }
    } catch (err) {
      if (err) {
        setLoader(false);
        return toast.error("הלקוחה כבר קיימת במערכת");
      }
    }
  }
  return (
    // <CacheProvider value={cacheRtl}>
    <Box>
      <div className="containerCustomers">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            top: -20,
          }}
        >
          <img src={Logo} alt="" />
        </Box>

        {!user?.is_login ? (
          <Login loader={loader} />
        ) : (
          <>
            <ButtonsCustomers
              setCheckAddCustomer={setCheckAddCustomer}
              setAddClient={setAddClient}
              checkAddCustomer={checkAddCustomer}
            />

            <div className="subContainerEvents">
              {!checkAddCustomer ? (
                <NewAppointment
                  loader={loader}
                  setLoader={setLoader}
                  allClients={allClients}
                  checkAddCustomer={checkAddCustomer}
                  addClient={addClient}
                  setAddClient={setAddClient}
                  handleAddEvent={handleAddEvent}
                  handleAddClient={handleAddClient}
                  setNewEvent={setNewEvent}
                  newEvent={newEvent}
                />
              ) : (
                <NewCustomer
                  loader={loader}
                  setLoader={setLoader}
                  allClients={allClients}
                  checkAddCustomer={checkAddCustomer}
                  addClient={addClient}
                  setAddClient={setAddClient}
                  handleAddEvent={handleAddEvent}
                  handleAddClient={handleAddClient}
                />
              )}

              {loader ? (
                <CircularProgress
                  sx={{ color: "#392613", marginTop: "50px" }}
                />
              ) : null}
            </div>

            <CalendarComp />
          </>
        )}
      </div>

      <div style={{ marginTop: "150px" }}>
        <Footer />
      </div>
    </Box>
  );
}

export default Customers;
