import * as React from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const maxTimeMinDay = new Date().setHours(8, 0, 0, 0);
const minTimeMaxDay = new Date().setHours(0, 0, 0, 0);

const daysFromRefDate = (date) =>
  date &&
  Math.floor((date.getTime() - date.getTimezoneOffset() * 60000) / 86400000);

const DateTimePicler = ({ minDate, maxDate, initDate }) => {
  const [selDate, setSelDate] = React.useState(initDate);
  const changeDate = React.useCallback((date) => setSelDate(date), []);

  const minDays = React.useMemo(() => daysFromRefDate(minDate), [minDate]);
  const maxDays = React.useMemo(() => daysFromRefDate(maxDate), [maxDate]);
  const selDays = React.useMemo(() => daysFromRefDate(selDate), [selDate]);
  const isMinDay = selDays === minDays;
  const isMaxDay = selDays === maxDays;

  const minTime = isMinDay ? minDate : isMaxDay ? minTimeMaxDay : null;
  const maxTime = isMaxDay ? maxDate : isMinDay ? maxTimeMinDay : null;

  return (
    <DatePicker
      ampm={false}
      selected={selDate}
      onChange={changeDate}
      // minDate={minDate}
      // maxDate={maxDate}
      minTime={minTime}
      maxTime={maxTime}
      showTimeSelect
      placeholderText="Select date and time"
      dateFormat="MM/dd/yyyy h:mm aa"
    />
  );
};

export default DateTimePicler;
