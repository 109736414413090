import "./details.scss";
import { Box } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import Logo from "../../images/navLogo.png";
import Button from "@mui/material/Button";
import CallIcon from "@mui/icons-material/Call";
import React from "react";

const Details = () => {
  const matches = useMediaQuery("(max-width:400px)");

  return (
    <Box id="containerDetails">
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <img src={Logo} alt="" />
      </Box>
      <div>
        <div className="subContainer">
          <p className="detailsContent">
            <div className="heart-shape"></div>

            <div>טל לופז עם חמש שנות וותק בתחום הקוסמטיקה והיופי.</div>
            {/* <br /> */}
            <div>
              עברתי קורסים השתלמויות אבל כמו שאומרים אין כמו הדרך שעוברים.
            </div>
            {/* <br /> */}
            <div>למדתי התפתחתי והגעתי לאן שאני היום אחרי הרבה עבודה קשה</div>
            {/* <br /> */}
            <div>ואני יכולה לומר בפה מלא שהשיטות עבודה שלי מקצועיות ביותר</div>
            {/* <br /> */}
            <div>כך גם הלקוחות שלי אומרות🌹 אני נמצאת</div>
            {/* <br /> */}
            <div>בירושלים בשכונת ארמון הנציב</div>
            {/* <br /> */}
            <div>אשמח לראותכן!</div>
            {/* <br /> */}
            <div>❤</div>
          </p>
          <Button
            id="call"
            className="buttonDetails"
            onClick={() => (window.location = "tel:+972509731118")}
            variant="contained"
          >
            לקביעת פגישה
            <CallIcon sx={{ position: "relative", right: "12px" }} />
          </Button>
        </div>
      </div>

      {/* {matches ? (
        <div>
          <h1 id="titleDetails">
            קצת על עצמי
            <span style={{ marginTop: "10px" }} className="borderTitle"></span>
          </h1>
          <div className="subContainer">
            <p
              style={{ fontSize: "12px", textAlign: "center" }}
              className="detailsContent"
            >
              טל לופז עם חמש שנות וותק בתחום הקוסמטיקה והיופי.
              <br />
              עברה קורסים השתלמויות אבל כמו שאומרים אין כמו הדרך שעוברים.
              <br />
              למדתי התפתחתי והגעתי לאן שאני היום אחרי הרבה עבודה קשה
              <br />
              ואני יכולה לומר בפה מלא שהשיטות עבודה שלי מקצועיות ביותר
              <br />
              כך גם הלקוחות שלי אומרות🌹 אני נמצאת
              <br />
              בירושלים בשכונת ארמון הנציב
              <br />
              אשמח לראותכן!
              <br />
            </p>
          </div>
        </div>
      ) : (
        <div>
          <h1 id="titleDetails">
            קצת על עצמי
            {/* <h2 className="subTitle">הסיפור שלי</h2> */}
      {/* <span style={{ marginTop: "10px" }} className="borderTitle"></span>
          </h1>
          <div className="subContainer">
            <p className="detailsContent">
              טל לופז עם חמש שנות וותק בתחום הקוסמטיקה והיופי.
              <br />
              עברה קורסים השתלמויות אבל כמו שאומרים אין כמו הדרך שעוברים.
              <br />
              למדתי התפתחתי והגעתי לאן שאני היום אחרי הרבה עבודה קשה
              <br />
              ואני יכולה לומר בפה מלא שהשיטות עבודה שלי מקצועיות ביותר
              <br />
              כך גם הלקוחות שלי אומרות🌹 אני נמצאת
              <br />
              בירושלים בשכונת ארמון הנציב
              <br />
              אשמח לראותכן!
            </p>
          </div>
        </div> */}
      {/* )} */}
    </Box>
  );
};

export default Details;
