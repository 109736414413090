import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import http from "../../services/http";
import useMediaQuery from "@mui/material/useMediaQuery";
import toast from "react-hot-toast";

import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import { endpoint1, endpoint2 } from "../../services/config";

import "./contact.scss";
const theme = createTheme({
  direction: "rtl", // Both here and <body dir="rtl">
});
// Create rtl cache
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [rtlPlugin],
});

const Contact = () => {
  const matches = useMediaQuery("(max-width:600px)");

  const [Name, setName] = useState("");
  const [Phone, setPhone] = useState("");
  // const [Email, setEmail] = useState("");
  const [Service, setService] = useState("");
  const [formSend, setFormSend] = useState(false);
  const [loader, setLoader] = useState(false);
  const services = [
    "הסרת שיער בלייזר",
    "בנייה באקריל",
    "לק ג'ל",
    "שעוות",
    "פדיקור",
    "מניקור",
  ];

  const handleChange = (event) => {
    setService(event.target.value);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    // let reEmail = /\S+@\S+\.\S+/;
    let rePhone = [
      /^[(]{0,1}[0-9]{3}[)]{0,1}[-s.]{0,1}[0-9]{3}[-s.]{0,1}[0-9]{4}$/,
    ];

    if (!Name && !Phone) {
      return toast.error("אנא מלא/י את כל השדות בבקשה ");
    }

    if (Name.length < 2) {
      return toast.error("אנא מלא/י את השם שלך בבקשה ");
    }
    if (Phone <= 2) {
      return toast.error("אנא מלא/י את הפלאפון שלך בבקשה ");
    }

    if (!rePhone[0].test(Phone)) {
      return toast.error("אנא מלא/י את מספר הפלאפון באופן תקין ");
    }

    if (!Service) {
      return toast.error("אנא מלא/י שירות רצוי בבקשה ");
    }

    // if (Email && !reEmail.test(Email)) {
    //   return toast.error("אנא מלא/י מייל תקין בבקשה ");
    // }

    setFormSend(true);

    try {
      setLoader(true);
      // const { status } = await http.post(
      //   `https://tal-lopez-contact.herokuapp.com`,
      const { status } = await http.post(endpoint1, {
        // const { status } = await http.post(`http://localhost:3001/tal-lopez`, {
        Name,
        Phone,
        // Email,
        Service,
        Date: new Date().toLocaleString(),
      });

      // console.log(status);
      if (status === 200) {
        return (
          toast.success(
            <>
              <div>
                ! הפרטים נשלחו בהצלחה
                <br />❤ אני אחזור אלייך בקרוב
              </div>
            </>,
            {
              duration: 6000,
            }
          ),
          setName(""),
          setPhone(""),
          setService(""),
          // setEmail(""),
          setFormSend(false),
          setLoader(false)
        );
      }
    } catch (error) {
      if (error) {
        setLoader(false);
        return (
          toast.error("משהו השתבש תשלח/י את הטופס מחדש"), setFormSend(false)
        );
      }
    }
  }

  return (
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <Box dir="rtl" id="containerContact">
          {/* {matches ? (
            <span
              style={{
                position: "relative",
                top: "0px",
                color: "red",
              }}
              id="contact"
            ></span>
          ) : (
            <span
              style={{
                position: "absolute",
                top: "-120px",
              }}
              id="contact"
            ></span>
          )} */}

          {/* <span id="contact"></span> */}
          <div id="contact"></div>
          <Box className="containerTitleContact">
            <h1 id="titleContact">
              יצירת קשר
              <span
                style={{ marginTop: "10px" }}
                className="borderTitle"
              ></span>
            </h1>
          </Box>

          <Box
            id="subContainerContact"
            component="form"
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
            sx={{
              "& > :not(style)": { m: 1 },
            }}
          >
            {matches ? (
              <>
                <TextField
                  className="inputContact"
                  sx={{ width: "140%" }}
                  id="outlined-multiline-flexible"
                  label="שם"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                />

                <TextField
                  className="inputContact"
                  sx={{ width: "140%" }}
                  id="outlined-multiline-flexible"
                  label="טלפון"
                  value={Phone}
                  onChange={(e) => setPhone(e.target.value)}
                />

                <FormControl sx={{ width: "140%", textAlign: "center" }}>
                  <InputLabel id="demo-simple-select-label">
                    שירות רצוי
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Service}
                    label="שירות רצוי"
                    onChange={handleChange}
                  >
                    <MenuItem value={services[0]}>הסרת שיער בלייזר</MenuItem>
                    <MenuItem value={services[1]}>בנייה באקריל</MenuItem>
                    <MenuItem value={services[2]}>לק ג'ל</MenuItem>
                    <MenuItem value={services[3]}>שעוות</MenuItem>
                    <MenuItem value={services[4]}>פדיקור</MenuItem>
                    <MenuItem value={services[5]}>מניקור</MenuItem>
                  </Select>
                </FormControl>

                {/* <TextField
                  helperText=" ✔ אמייל לא חובה"
                  className="inputContact"
                  sx={{ width: "140%" }}
                  id="outlined-multiline-flexible"
                  label="אימייל"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                /> */}
                {loader ? <CircularProgress sx={{ color: "#392613" }} /> : null}
              </>
            ) : (
              <>
                <TextField
                  className="inputContact"
                  sx={{ width: "190%" }}
                  id="outlined-multiline-flexible"
                  label="שם"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                />

                <TextField
                  className="inputContact"
                  sx={{ width: "190%" }}
                  id="outlined-multiline-flexible"
                  label="טלפון"
                  value={Phone}
                  type="phone"
                  onChange={(e) => setPhone(e.target.value)}
                />

                <FormControl sx={{ width: "190%", textAlign: "center" }}>
                  <InputLabel id="demo-simple-select-label">
                    שירות רצוי
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={Service}
                    label="שירות רצוי"
                    onChange={handleChange}
                  >
                    <MenuItem value={services[0]}>הסרת שיער בלייזר</MenuItem>
                    <MenuItem value={services[1]}>בנייה באקריל</MenuItem>
                    <MenuItem value={services[2]}>לק ג'ל</MenuItem>
                    <MenuItem value={services[3]}>שעוות</MenuItem>
                    <MenuItem value={services[4]}>פדיקור</MenuItem>
                    <MenuItem value={services[5]}>מניקור</MenuItem>
                  </Select>
                </FormControl>

                {/* <TextField
                  helperText=" ✔ אמייל לא חובה"
                  className="inputContact"
                  sx={{ width: "190%" }}
                  id="outlined-multiline-flexible"
                  label="אימייל"
                  value={Email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                /> */}
                {loader ? <CircularProgress sx={{ color: "#392613" }} /> : null}
              </>
            )}

            {!formSend ? (
              <Button
                type="submit"
                className="buttonContact"
                variant="contained"
                size="large"
              >
                שלח
              </Button>
            ) : (
              <Button
                disabled
                type="submit"
                size="large"
                className="buttonContact"
              >
                שלח
              </Button>
            )}
          </Box>
        </Box>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default Contact;
