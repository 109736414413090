import { CacheProvider } from "@emotion/react";
import { Button, useMediaQuery } from "@mui/material";

import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import TextField from "@mui/material/TextField";

const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [rtlPlugin],
});

const NewCustomer = ({
  checkAddCustomer,
  addClient,
  setAddClient,
  loader,
  handleAddEvent,
  handleAddClient,
}) => {
  const matches = useMediaQuery("(max-width:500px)");

  return (
    <div className="containerInputsAddClient">
      <CacheProvider value={cacheRtl}>
        <TextField
          className="inputCustomer"
          // sx={{ width: "20%" }}
          id="outlined-multiline-flexible"
          label="שם"
          value={addClient.name}
          onChange={(e) => setAddClient({ ...addClient, name: e.target.value })}
        />
        <TextField
          className="inputCustomer"
          // sx={{ width: "20%" }}
          id="outlined-multiline-flexible"
          label="טלפון"
          value={addClient.phone}
          onChange={(e) =>
            setAddClient({ ...addClient, phone: e.target.value })
          }
        />
      </CacheProvider>

      <Button
        disabled={loader}
        type="submit"
        className={!checkAddCustomer ? "buttonNewEvent" : "buttonNewClient"}
        variant="contained"
        size={matches ? "large" : "large"}
        onClick={!checkAddCustomer ? handleAddEvent : handleAddClient}
        sx={{
          height: !matches ? "55px" : "40px",
          fontWeight: "bold",
        }}
        color="secondary"
      >
        {!checkAddCustomer ? "קבעי תור" : " הוספת לקוחה"}
      </Button>
    </div>
  );
};

export default NewCustomer;
