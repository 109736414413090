import dayjs from "dayjs";
import React, { useState, useContext } from "react";

// Mui
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import BackspaceIcon from "@mui/icons-material/Backspace";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { CacheProvider } from "@emotion/react";

import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import { adminPanelContext } from "../../../services/conext/adminPanel";
import "dayjs/locale/he";

const NewAppointment = ({
  checkAddCustomer,
  loader,
  handleAddEvent,
  handleAddClient,
  setNewEvent,
  newEvent,
}) => {
  const matches = useMediaQuery("(max-width:500px)");
  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [rtlPlugin],
  });

  const { allClients } = useContext(adminPanelContext);

  const services = ["בנייה באקריל", "לק ג'ל", "שעוות", "פדיקור", "מניקור"];

  return (
    <LocalizationProvider adapterLocale="he" dateAdapter={AdapterDayjs}>
      <div className="containerAddApp">
        <CacheProvider value={cacheRtl}>
          <Autocomplete
            className="inputAddEvent"
            sx={{ width: "100%" }}
            options={allClients}
            autoHighlight
            onChange={(event, newValue) => {
              console.log(newValue);
              setNewEvent({ ...newEvent, id: newValue._id });
            }}
            getOptionLabel={(option) => option.name}
            // getOptionSelected={(option, value) => option.name === value.name}
            // getOptionSelected={(option, value) => option.value === value.value}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{
                  "& > a": {},
                }}
                {...props}
                key={option._id}
              >
                <a
                  style={{
                    marginRight: !matches ? "150px" : "100px",
                    color: "#7b1fa2",
                    textAlign: "center",
                  }}
                  href={`tel:+972${option.phone}`}
                >
                  <PhoneEnabledIcon />
                </a>

                {option.name}
              </Box>
            )}
            renderInput={(params, option) => (
              <TextField
                onChange={() => setNewEvent({ ...newEvent, id: option.id })}
                {...params}
                label="בחרי לקוחה"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </CacheProvider>

        <FormControl className="formControlAddApp">
          <CacheProvider value={cacheRtl}>
            <InputLabel id="demo-simple-select-label">סוג טיפול</InputLabel>

            <Select
              className="inputAddEvent"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={newEvent.title}
              label="סוג טיפול"
              onChange={(e) =>
                setNewEvent({ ...newEvent, title: e.target.value })
              }
            >
              {services.map((service) => (
                <MenuItem sx={{}} key={service} value={service}>
                  {service}
                </MenuItem>
              ))}

              {/* <MenuItem className="selectMenuItem" value={services[0]}>
            בנייה באקריל
          </MenuItem>
          <MenuItem value={services[1]}>לק ג'ל</MenuItem>
          <MenuItem value={services[2]}>שעוות</MenuItem>
          <MenuItem value={services[3]}>פדיקור</MenuItem>
          <MenuItem value={services[4]}>מניקור</MenuItem> */}
            </Select>
          </CacheProvider>

          {/* <ThemeProvider theme={theme}> */}

          <MobileDateTimePicker
            // max={maxTimeMinDay}
            // min={minTimeMaxDay}
            // minTime={new Date().setHours(8, 0, 0, 0)}
            // maxTime={new Date().setHours(20, 0, 0, 0)}
            ampm={false}
            className="inputAddEvent"
            renderInput={(params) => <TextField {...params} />}
            label="התחלה"
            value={newEvent.start}
            onChange={(newValue) =>
              setNewEvent({ ...newEvent, start: newValue.$d })
            }
            // minDateTime={dayjs()}
          />

          <MobileDateTimePicker
            // maxDateTime={1 < 10}
            // disableIgnoringDatePartForTimeValidation={false}
            // minTime={"07:00"}
            // maxTime={"20:00"}
            ampm={false}
            className="inputAddEvent"
            renderInput={(params) => <TextField {...params} />}
            label="סיום"
            value={newEvent.end}
            onChange={(newValue) =>
              setNewEvent({ ...newEvent, end: newValue.$d })
            }
          />
          {/* </ThemeProvider> */}

          <Button
            disabled={loader}
            type="submit"
            className={!checkAddCustomer ? "buttonNewEvent" : "buttonNewClient"}
            variant="contained"
            size={matches ? "large" : "large"}
            onClick={!checkAddCustomer ? handleAddEvent : handleAddClient}
            sx={{
              height: !matches ? "55px" : "40px",
              fontWeight: "bold",
            }}
            color="secondary"
          >
            {!checkAddCustomer ? "קבעי תור" : " הוספת לקוחה"}
          </Button>
        </FormControl>
      </div>
    </LocalizationProvider>
  );
};

export default NewAppointment;
